import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Inversion_of_control"
      }}>{`Inversion of control`}</a>{` and
more specifically
`}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Dependency_injection"
      }}>{`dependency injection`}</a>{` have
been growing in popularity in the JavaScript landscape thanks to projects like
`}<a parentName="p" {...{
        "href": "http://requirejs.org/"
      }}>{`Require.js`}</a>{` and `}<a parentName="p" {...{
        "href": "http://angularjs.org/"
      }}>{`AngularJS`}</a>{`. This
article is a brief introduction to dependency injection and how it fits into
JavaScript. It will also demystify the elegant way AngularJS implements
dependency injection.`}</p>
    <h2>{`Dependency Injection In JavaScript`}</h2>
    <p>{`Dependency injection facilitates better testing by allowing us to mock
dependencies in testing environments so that we only test one thing at a time.
It also enables us to write more maintainable code by decoupling our objects
from their implementations.`}</p>
    <p>{`With dependency injection, your dependencies are given to your object instead of
your object creating or explicitly referencing them. This means the dependency
injector can provide a different dependency based on the context of the
situation. For example, in your tests, it might pass a fake version of your
services API that doesn't make requests but returns static objects instead,
while in production it provides the actual services API.`}</p>
    <p>{`Another example could be to pass `}<a parentName="p" {...{
        "href": "http://zeptojs.com/"
      }}>{`ZeptoJS`}</a>{` to your view
objects when the device is running `}<a parentName="p" {...{
        "href": "http://www.webkit.org/"
      }}>{`Webkit`}</a>{` instead of
`}<a parentName="p" {...{
        "href": "http://jquery.com/"
      }}>{`jQuery`}</a>{` to improve performance.`}</p>
    <p>{`The main benefits experienced by adopting dependency injection are as follows:`}</p>
    <ol>
      <li parentName="ol">{`Code tends to be more maintainable.`}</li>
      <li parentName="ol">{`APIs are more elegant and abstract.`}</li>
      <li parentName="ol">{`Code is easier to test.`}</li>
      <li parentName="ol">{`The code is more modular and reusable.`}</li>
      <li parentName="ol">{`Cures cancer. (Not entirely true.)`}</li>
    </ol>
    <p>{`Holding dependencies to an API based contract becomes a natural process. Coding
to interfaces is nothing new, the server side world has been battle testing this
idea for a long time to the extent that the languages themselves implement the
concept of interfaces. In JavaScript, we have to force ourselves to do this.
Fortunately, dependency injection and module systems are a welcome friend.`}</p>
    <p>{`Now that you have some idea of what dependency injection is, let's take a look
at how to build a simple implementation of a dependency injector using
`}<a parentName="p" {...{
        "href": "http://docs.angularjs.org/guide/di"
      }}>{`AngularJS style dependency injection`}</a>{` as a
reference implementation. This implementation is purely for didactic purposes.`}</p>
    <h2>{`AngularJS Style Injection`}</h2>
    <p>{`AngularJS is one of the only front-end JavaScript frameworks that fully adopts
dependency injection right down to the core of the framework. To a lot of
developers, the way dependency injection is implemented in AngularJS looks
completely magic.`}</p>
    <p>{`When creating controllers in AngularJS, the arguments are dependency names that
will be injected into your controller. The argument names are the key here, they
are leveraged to map a dependency name to an actual dependency. Yeah, the word
"key" was used on purpose, you will see why.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Injected */`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`WelcomeController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`Greeter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/** I want a different Greeter injected dynamically. **/`}</span>{`
  Greeter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`greet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2>{`Basic Requirements`}</h2>
    <p>{`Let's explore some of the requirements to make this function work as expected.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The dependency container needs to know that this function wants to be
processed. In the AngularJS world that is done through the Application
object and the declarative HTML bindings. In our world, we will explicitly
ask our injector to process a function.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`It needs to know what a Greeter before it can inject it.`}</p>
      </li>
    </ol>
    <h3>{`Requirement 1: Making the injector aware.`}</h3>
    <p>{`To make our dependency injector aware of our WelcomeController we will simply
tell our injector we want a function processed. It's important to know AngularJS
ultimately does this same thing just using less obvious mechanisms whether that
be the Application object or the HTML declarations.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` Injector `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`process`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Time to process`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

Injector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`process`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`WelcomeController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Ok, now that the Injector has the opportunity to process the WelcomeController
we can figure out what dependencies the function wants, and execute it with the
proper dependencies. This process is called dependency resolution. Before we can
do that we need a way to register dependencies with our Injector object...`}</p>
    <h3>{`Requirement 2: Registering dependencies`}</h3>
    <p>{`We need to be able to tell the dependency injector what a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Greeter`}</code>{` is before it
can provide one. Any dependency injector worth it's bits will allow you to
describe `}<em parentName="p">{`how`}</em>{` it is provided. Whether that means being instantiated as a new
object or returning a singleton. Most injection frameworks even have mechanisms
to provide a constructor some configuration and register multiple dependencies
by the same name. Since our dependency injector is just a simplified way to show
how AngularJS does dependency mapping using parameter names, we won't worry
about any of that.`}</p>
    <p>{`Without further excuses, our simple register function:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`Injector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`dependencies `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

Injector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`register`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`name`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` dependency`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`dependencies`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` dependency`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`All we do is store our dependency by name so the injector knows what to provide
when certain dependencies are requested. Let's go ahead and register an
implementation of Greeter.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` RobotGreeter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`greet`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Domo Arigato"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

Injector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`register`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Greeter"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` RobotGreeter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Now our injector knows what to provide when `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Greeter`}</code>{` is specified as a
dependency.`}</p>
    <h2>{`Moving Forward`}</h2>
    <p>{`The building blocks are in place it's time for the sweet part of this article.
The reason I wanted to post this article in the first place, the nutrients, the
punch line, the hook, the call toString() with some sweet reflection. This is
where the magic is, in JavaScript, we don't have to execute a function
immediately. The trick is to call toString on your function which returns the
function as a string, this gives a chance to preprocess our functions as strings
and turn them back into functions using the Function constructor, or just
execute them with the proper parameters after doing some reflection. The latter
is exactly what we will do here.`}</p>
    <h3>{`toString Returns Winning`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`WelcomeController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`Greeter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  Greeter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`greet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Returns the function as a string.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` processable `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` WelcomeController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`You can try it on your console!`}</p>
    <p><img parentName="p" {...{
        "src": "/assets/images/articles/javascript-dependency-injection/processable.png",
        "alt": "Function toString Example",
        "title": "Function toString Example"
      }}></img></p>
    <p>{`Now that we have the WelcomeController as a string we can do some reflection to
figure out which dependencies to inject.`}</p>
    <h3>{`Dependency Checking`}</h3>
    <p>{`It's time to implement the process method of our Injector. First, let's take a
look at
`}<a parentName="p" {...{
        "href": "https://github.com/angular/angular.js/blob/master/src/auto/injector.js"
      }}>{`injector.js`}</a>{`
from Angular. You'll notice the reflection starts on
`}<a parentName="p" {...{
        "href": "https://github.com/angular/angular.js/blob/master/src/auto/injector.js#L54"
      }}>{`line 54`}</a>{`
and leverages a few regular expressions to parse the function. Let's take a look
at the regular expression, shall we?`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FN_ARGS`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token regex"
          }}><span parentName="span" {...{
              "className": "token regex-delimiter"
            }}>{`/`}</span><span parentName="span" {...{
              "className": "token regex-source language-regex"
            }}>{`^function\\s*[^\\(]*\\(\\s*([^\\)]*)\\)`}</span><span parentName="span" {...{
              "className": "token regex-delimiter"
            }}>{`/`}</span><span parentName="span" {...{
              "className": "token regex-flags"
            }}>{`m`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FN_ARGS`}</code>{` regular expression is used to select everything inside the
parentheses of a function definition. In other words the parameters of a
function. In our case, the dependency list.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` args `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` WelcomeController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`match`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FN_ARGS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Returns Greeter`}</span></code></pre></div>
    <p>{`Pretty neat, right? We have now parsed out the WelcomeController's dependency
list in our Injector `}<em parentName="p">{`prior`}</em>{` to executing the WelcomeController function!
Suppose the WelcomeController had multiple dependencies, this isn't terribly
problematic since we can just split the arguments with a comma!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`MultipleDependenciesController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`Greeter`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` OtherDependency`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Implementation of MultipleDependenciesController`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` args `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` MultipleDependenciesController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`match`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FN_ARGS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`split`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`","`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Returns ['Greeter', 'OtherDependency']`}</span></code></pre></div>
    <p>{`The rest is pretty straightforward, we just grab the requested dependency by
name from our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dependencies`}</code>{` cache and call the target function passing the
requested dependencies as arguments. Let's implement the function that maps our
array of dependency names to their dependencies:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`Injector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`getDependencies`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`arr`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` self `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` arr`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`dependencyName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`dependencies`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`dependencyName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getDependencies`}</code>{` method takes the array of dependency names and maps it to
a corresponding array of actual dependencies. If this map function is foreign to
you check out the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/JavaScript/Reference/Global_Objects/Array/map"
      }}>{`Array.prototype.map documentation`}</a>{`.`}</p>
    <p>{`Now that we have implemented our dependency resolver we can head back over to
our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`process`}</code>{` method and execute the target function with its proper
dependencies.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`apply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`target`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDependencies`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Pretty awesome, right?`}</p>
    <h3>{`Injector.js`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` Injector `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  dependencies`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`process`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FN_ARGS`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token regex"
          }}><span parentName="span" {...{
              "className": "token regex-delimiter"
            }}>{`/`}</span><span parentName="span" {...{
              "className": "token regex-source language-regex"
            }}>{`^function\\s*[^\\(]*\\(\\s*([^\\)]*)\\)`}</span><span parentName="span" {...{
              "className": "token regex-delimiter"
            }}>{`/`}</span><span parentName="span" {...{
              "className": "token regex-flags"
            }}>{`m`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` text `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` target`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` args `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` text`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`match`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FN_ARGS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`split`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`","`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`target`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`apply`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`target`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDependencies`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`getDependencies`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`arr`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` self `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` arr`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`dependencies`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`register`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`name`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` dependency`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`dependencies`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` dependency`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Example & Excuses`}</h3>
    <p>{`You can see the functioning injector we created in this
`}<a parentName="p" {...{
        "href": "https://jsfiddle.net/nMK6j/"
      }}>{`example`}</a>{` on jsFiddle.`}</p>
    <iframe style={{
      "width": "100%",
      "maxWidth": "664px",
      "height": "500px"
    }} src="https://jsfiddle.net/nMK6j/1/embedded/" allowFullScreen="allowfullscreen" frameBorder="0"></iframe>
    <p>{`This contrived example is not something you would use in an actual codebase it
was simply created to demonstrate the rich functionality JavaScript provides and
to explain how AngularJS provides dependency injection. If this interests you I
highly recommend reviewing their code further. It's important to note this
approach is not novel. Other projects use toString to preprocess code, for
example `}<a parentName="p" {...{
        "href": "https://requirejs.org"
      }}>{`Require.js`}</a>{` uses a similar approach to parse and
transpile CommonJS style modules to AMD style modules.`}</p>
    <p>{`I hope you found this article enlightening and continue to explore dependency
injection and how it applies to the client side world.`}</p>
    <p>{`I really think there is something special brewing here.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      